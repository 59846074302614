import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  VStack,
  Heading,
  Text,
  useToast,
  Container,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import { db } from "../firebase";
import { collection, doc, writeBatch } from "firebase/firestore";
import AgentInput from "../components/AgentInput";
import Navbar from "../components/Navbar";

const validationSchema = Yup.object().shape({
  agents: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required("Name is required"),
        email: Yup.string()
          .email("Invalid email")
          .required("Email is required"),
      })
    )
    .min(1, "Add at least one Fundraiser")
    .max(10, "You can add up to 10 Fundraisers at a time"),
});

const AddAgents = () => {
  const { campaignId } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();
  const idToken = sessionStorage.getItem("idToken");

  const handleSubmit = async (values, { resetForm }) => {
    setIsSubmitting(true);
    const batch = writeBatch(db);
    const agentsRef = collection(doc(db, "campaigns", campaignId), "agents");

    values.agents.forEach((agent) => {
      const newAgentRef = doc(agentsRef);
      batch.set(newAgentRef, agent);
    });

    try {
      await batch.commit();
      toast({
        title: "Fundraisers added successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      resetForm();
    } catch (error) {
      console.error("Error adding fundraisers: ", error);
      toast({
        title: "Error adding fundraisers",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const headingSize = useBreakpointValue({ base: "lg", md: "xl" });
  const buttonSize = useBreakpointValue({ base: "sm", md: "md" });
  const containerWidth = useBreakpointValue({
    base: "100%",
    md: "80%",
    lg: "60%",
  });

  return (
    <>
      <Navbar />
      <Box minH="calc(100vh - 60px)" bg="primaryBackground" pt="60px" px={4}>
        <VStack
          spacing={8}
          align="center"
          maxWidth="500px"
          margin="0 auto"
          pt={8}
        >
          <Heading as="h1" size="lg" textAlign="center" color="heading">
            Add Fundraisers to Campaign
          </Heading>
          <Text textAlign="center" color="text">
            Add fundraisers to your campaign by filling out the form below.
          </Text>
          <Box
            width="100%"
            bg="secondaryBackground"
            p={10}
            borderRadius="xl"
            boxShadow="md"
          >
            <Formik
              initialValues={{ agents: [{ name: "", email: "" }] }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values }) => (
                <Form>
                  <VStack spacing={4}>
                    <FieldArray name="agents">
                      {({ push, remove }) => (
                        <>
                          {values.agents.map((_, index) => (
                            <AgentInput
                              key={index}
                              index={index}
                              remove={remove}
                              isLast={index === values.agents.length - 1}
                              isRequired={true}
                            />
                          ))}
                          {values.agents.length < 10 && (
                            <Button
                              onClick={() => push({ name: "", email: "" })}
                              variant={"primary"}
                              size={buttonSize}
                              width="100%"
                            >
                              Add Another Fundraiser
                            </Button>
                          )}
                        </>
                      )}
                    </FieldArray>
                    <Text fontSize="sm" color="gray.600">
                      You can add up to 10 agents at a time.
                    </Text>
                    <Button
                      type="submit"
                      variant={"secondary"}
                      isLoading={isSubmitting}
                      loadingText="Adding Agents"
                      size={buttonSize}
                      width="100%"
                    >
                      Add Fundraisers to Campaign
                    </Button>
                  </VStack>
                </Form>
              )}
            </Formik>
          </Box>
        </VStack>
      </Box>
    </>
  );
};

export default AddAgents;
