import React, { createContext, useState, useContext } from "react";
import translations from "../languages/languages.json";

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState("en");

  const translate = (key) => {
    const keys = key.split(".");
    let value = translations;
    for (let k of keys) {
      if (value && typeof value === "object" && k in value) {
        value = value[k];
      } else {
        console.error(`Translation key not found: ${key}`);
        return key; // Return the key itself if translation is not found
      }
    }
    if (value && typeof value === "object" && language in value) {
      return value[language];
    } else {
      console.error(
        `Translation not found for language "${language}" and key "${key}"`
      );
      return key; // Return the key itself if translation is not found
    }
  };

  return (
    <LanguageContext.Provider value={{ language, setLanguage, translate }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
