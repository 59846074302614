import React from "react";
import { Box, VStack, Heading, Text, Image } from "@chakra-ui/react";
import GoogleSignInButton from "../components/GoogleSignInButton";
import logo from "../assets/logos/logo.png";

const SignIn = () => {
  return (
    <Box
      width="100%"
      height="100vh"
      bg="primaryBackground"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Image src={logo} alt="Logo" width="150px" mb={8} />
      <Box
        maxWidth="400px"
        width="100%"
        bg="secondaryBackground"
        p={10}
        borderRadius="xl"
        boxShadow="md"
      >
        <VStack spacing={6} align="stretch">
          <Heading as="h1" size="xl" textAlign="center" color="heading">
            Sign In
          </Heading>
          <Text textAlign="center" color="text">
            Welcome to the Campaign Portal. Please sign in to continue.
          </Text>
          <GoogleSignInButton text="Sign in with Google" />
        </VStack>
      </Box>
    </Box>
  );
};

export default SignIn;
