import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Spinner, useDisclosure, Text } from "@chakra-ui/react";
import { FaGoogle } from "react-icons/fa";
import { UserAuth } from "../contexts/AuthContext";
// import { parseError } from "../tools/FirebaseErrorParser";

const GoogleSignInButton = ({ text }) => {
  const navigate = useNavigate();
  const [loadingGoogle, setLoadingGoogle] = useState(false);
  const [firebaseError, setFirebaseError] = useState("");
  const { signInWithGoogle } = UserAuth();

  const handleSubmitGoogle = async () => {
    setFirebaseError("");
    setLoadingGoogle(true);

    try {
      await signInWithGoogle();
      setTimeout(() => {
        setLoadingGoogle(false);
        navigate("/home");
      }, 1000);
    } catch (e) {
      setLoadingGoogle(false);
      setFirebaseError(e.message);
      console.log(e.message);
    }
  };

  return (
    <div>
      {firebaseError && (
        <Text fontSize={"sm"} color="#E53A5A">
          {firebaseError}
        </Text>
      )}

      <Button
        width={"full"}
        mb={8}
        leftIcon={<FaGoogle />}
        variant={"google"}
        onClick={handleSubmitGoogle}
        disabled={loadingGoogle}
      >
        {loadingGoogle ? <Spinner size="sm" /> : text}
      </Button>
    </div>
  );
};

export default GoogleSignInButton;
