import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  VStack,
  Select,
  Heading,
  Input,
  useToast,
  Container,
  useBreakpointValue,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import LogoutButton from "../components/LogoutButton";
import Navbar from "../components/Navbar";

const Home = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [passcode, setPasscode] = useState("");
  const navigate = useNavigate();
  const toast = useToast();

  const buttonSize = useBreakpointValue({ base: "md", md: "md" });
  const headingSize = useBreakpointValue({ base: "xl", md: "xl" });
  const spacing = useBreakpointValue({ base: 4, md: 6 });

  useEffect(() => {
    const fetchCampaigns = async () => {
      const campaignsRef = collection(db, "campaigns");
      const querySnapshot = await getDocs(campaignsRef);
      const campaignsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name,
      }));
      setCampaigns(campaignsData);
    };

    fetchCampaigns();
  }, []);

  const handleCreateCampaign = () => {
    navigate("/create-campaign");
  };

  const handleSelectCampaign = (event) => {
    setSelectedCampaign(event.target.value);
    setPasscode("");
  };

  const handlePasscodeChange = (event) => {
    setPasscode(event.target.value);
  };

  const handleEnterCampaign = async () => {
    if (selectedCampaign && passcode) {
      try {
        const campaignRef = doc(db, "campaigns", selectedCampaign);
        const campaignDoc = await getDoc(campaignRef);

        if (campaignDoc.exists() && campaignDoc.data().passcode === passcode) {
          navigate(`/campaign/${selectedCampaign}`);
        } else {
          toast({
            title: "Invalid passcode",
            description: "Please enter the correct passcode for this campaign.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error("Error checking passcode:", error);
        toast({
          title: "Error",
          description:
            "An error occurred while checking the passcode. Please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <>
      <Navbar />
      <Box minH="calc(100vh - 60px)" bg="primaryBackground" pt="60px" px={4}>
        <VStack
          spacing={8}
          align="center"
          maxWidth="500px"
          margin="0 auto"
          pt={8}
        >
          <Heading as="h1" size="lg" textAlign="center" color="heading">
            Home
          </Heading>
          <Text textAlign="center" color="text">
            Create a new campaign or select an existing one.
          </Text>
          <Box
            width="100%"
            bg="secondaryBackground"
            p={10}
            borderRadius="xl"
            boxShadow="md"
          >
            <VStack spacing={4}>
              <Button
                variant={"primary"}
                onClick={handleCreateCampaign}
                width="100%"
              >
                Create a Campaign
              </Button>
              <Text color="text">Or</Text>
              <Select
                placeholder="Select an existing campaign"
                value={selectedCampaign}
                onChange={handleSelectCampaign}
              >
                {campaigns.map((campaign) => (
                  <option key={campaign.id} value={campaign.id}>
                    {campaign.name}
                  </option>
                ))}
              </Select>
              <Input
                placeholder="Enter campaign passcode"
                type="password"
                value={passcode}
                onChange={handlePasscodeChange}
                isDisabled={!selectedCampaign}
              />
              <Button
                variant={"secondary"}
                onClick={handleEnterCampaign}
                isDisabled={!selectedCampaign || !passcode}
                width="100%"
              >
                Enter Campaign
              </Button>
            </VStack>
          </Box>
        </VStack>
      </Box>
    </>
  );
};

export default Home;
