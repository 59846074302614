import React from "react";
import {
  Box,
  Button,
  VStack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
  Container,
  Heading,
  useBreakpointValue,
  Textarea,
  Text,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { db } from "../firebase";
import { collection, addDoc, doc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Campaign name is required"),
  endDate: Yup.date().required("End date is required"),
  contactName: Yup.string().required("Contact name is required"),
  contactEmail: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
  contactPhone: Yup.string()
    .matches(/^\d{10}$/, "Phone number must be 10 digits")
    .required("Phone number is required"),
  passcode: Yup.string()
    .min(4, "Passcode must be at least 4 characters")
    .required("Passcode is required"),
  couponBookPrice: Yup.number()
    .positive("Price must be positive")
    .required("Coupon book price is required"),
  description: Yup.string()
    .max(1000, "Description must be at most 1000 characters")
    .required("Description is required"),
  offeringURL: Yup.string().url("Invalid URL").nullable(),
});

const CreateCampaign = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);

  const handleShowClick = () => setShowPassword(!showPassword);

  const handleSubmit = async (values, actions) => {
    try {
      const docRef = await addDoc(collection(db, "campaigns"), {
        name: values.name,
        endDate: values.endDate,
        contactName: values.contactName,
        contactEmail: values.contactEmail,
        contactPhone: values.contactPhone,
        passcode: values.passcode,
        couponBookPrice: values.couponBookPrice,
        description: values.description,
        offeringURL: values.offeringURL || null,
      });
      console.log("Document written with ID: ", docRef.id);

      // Create a sub-collection of agents and add an agent called "General"
      const agentRef = doc(collection(db, `campaigns/${docRef.id}/agents`));
      await setDoc(agentRef, { name: "General" });

      actions.setSubmitting(false);
      navigate(`/campaign/${docRef.id}`);
    } catch (error) {
      console.error("Error adding document: ", error);
      actions.setSubmitting(false);
    }
  };

  const formWidth = useBreakpointValue({ base: "100%", md: "70%", lg: "50%" });
  const buttonSize = useBreakpointValue({ base: "md", md: "md" });
  const headingSize = useBreakpointValue({ base: "xl", md: "xl" });

  return (
    <>
      <Navbar />
      <Box
        minH="calc(100vh - 60px)" // Adjust this value based on your Navbar height
        bg="primaryBackground"
        pt="60px" // Add top padding to account for the Navbar
        px={4} // Add horizontal padding
      >
        <VStack
          spacing={8}
          align="center"
          maxWidth="500px"
          margin="0 auto"
          pt={8} // Add top padding to the content
        >
          <Heading as="h1" size="lg" textAlign="center" color="heading">
            New Campaign
          </Heading>
          <Text textAlign="center" color="text">
            Create a new campaign by filling out the form below.
          </Text>
          <Box
            width="100%"
            bg="secondaryBackground"
            p={10}
            borderRadius="xl"
            boxShadow="md"
          >
            <Formik
              initialValues={{
                name: "",
                endDate: "",
                contactName: "",
                contactEmail: "",
                contactPhone: "",
                passcode: "",
                couponBookPrice: "",
                description: "",
                offeringURL: "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {(props) => (
                <Form>
                  <VStack spacing={4}>
                    <Field name="name">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.name && form.touched.name}
                          isRequired
                        >
                          <FormLabel htmlFor="name">Campaign Name</FormLabel>
                          <Input
                            {...field}
                            id="name"
                            placeholder="Enter campaign name"
                          />
                          <FormErrorMessage>
                            {form.errors.name}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="endDate">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.endDate && form.touched.endDate
                          }
                          isRequired
                        >
                          <FormLabel htmlFor="endDate">End Date</FormLabel>
                          <Input {...field} id="endDate" type="date" />
                          <FormErrorMessage>
                            {form.errors.endDate}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="contactName">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.contactName && form.touched.contactName
                          }
                          isRequired
                        >
                          <FormLabel htmlFor="contactName">
                            Contact Name
                          </FormLabel>
                          <Input
                            {...field}
                            id="contactName"
                            placeholder="Enter contact name"
                          />
                          <FormErrorMessage>
                            {form.errors.contactName}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="contactEmail">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.contactEmail &&
                            form.touched.contactEmail
                          }
                          isRequired
                        >
                          <FormLabel htmlFor="contactEmail">
                            Contact Email
                          </FormLabel>
                          <Input
                            {...field}
                            id="contactEmail"
                            type="email"
                            placeholder="Enter contact email"
                          />
                          <FormErrorMessage>
                            {form.errors.contactEmail}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="contactPhone">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.contactPhone &&
                            form.touched.contactPhone
                          }
                          isRequired
                        >
                          <FormLabel htmlFor="contactPhone">
                            Contact Phone
                          </FormLabel>
                          <Input
                            {...field}
                            id="contactPhone"
                            placeholder="Enter contact phone"
                          />
                          <FormErrorMessage>
                            {form.errors.contactPhone}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="passcode">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.passcode && form.touched.passcode
                          }
                          isRequired
                        >
                          <FormLabel htmlFor="passcode">Passcode</FormLabel>
                          <InputGroup>
                            <Input
                              {...field}
                              id="passcode"
                              type={showPassword ? "text" : "password"}
                              placeholder="Enter passcode"
                            />
                            <InputRightElement width="4.5rem">
                              <Button
                                h="1.75rem"
                                size="sm"
                                onClick={handleShowClick}
                              >
                                {showPassword ? "Hide" : "Show"}
                              </Button>
                            </InputRightElement>
                          </InputGroup>
                          <FormErrorMessage>
                            {form.errors.passcode}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="couponBookPrice">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.couponBookPrice &&
                            form.touched.couponBookPrice
                          }
                          isRequired
                        >
                          <FormLabel htmlFor="couponBookPrice">
                            Coupon Book Price (In CAD)
                          </FormLabel>
                          <Input
                            {...field}
                            id="couponBookPrice"
                            type="number"
                            step="0.01"
                            placeholder="Enter coupon book price"
                          />
                          <FormErrorMessage>
                            {form.errors.couponBookPrice}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="description">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.description && form.touched.description
                          }
                          isRequired
                        >
                          <FormLabel htmlFor="description">
                            Description
                          </FormLabel>
                          <Textarea
                            {...field}
                            id="description"
                            placeholder="Enter campaign description"
                          />
                          <FormErrorMessage>
                            {form.errors.description}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="offeringURL">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.offeringURL && form.touched.offeringURL
                          }
                        >
                          <FormLabel htmlFor="offeringURL">
                            Offering Page URL (Optional)
                          </FormLabel>
                          <Input
                            {...field}
                            id="offeringURL"
                            type="url"
                            placeholder="Enter offering page URL"
                          />
                          <FormErrorMessage>
                            {form.errors.offeringURL}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Button
                      mt={4}
                      variant={"primary"}
                      isLoading={props.isSubmitting}
                      type="submit"
                      width="100%"
                    >
                      Create Campaign
                    </Button>
                  </VStack>
                </Form>
              )}
            </Formik>
          </Box>
        </VStack>
      </Box>
    </>
  );
};

export default CreateCampaign;
