"use client";

import { Box, Heading, Text, Button, Flex } from "@chakra-ui/react";
import { CheckCircleIcon, WarningTwoIcon, CloseIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

export default function Result({ result, type }) {
  const navigate = useNavigate();
  let headline = "";
  let body = "";

  if (result === "success" && type === "payment") {
    headline = "Purchase Successful";
    body =
      "Thank you for supporting this campaign! You will recieve an email with your coupon book shortly.";
  } else if (result === "cancellation" && type === "payment") {
    headline = "Purchase Cancelled";
    body =
      "Your purchase has been cancelled, and no charges have been made to your method of payment. If you require assistance, please contact us.";
  } else if (result === "failure" && type === "payment") {
    headline = "Purchase Failed";
    body =
      "There has been an issue with your purchase, and no charges have been made to your method of payment. If you require assistance, please contact us.";
  } else if (result === "failure" && type === "other") {
    headline = "Something went wrong";
    body =
      "We ran into an issue. Please try again, and if the issue persists, reach out for assitance.";
  }

  return (
    <>
      <Box
        minH={"100vh"}
        display="flex"
        justifyContent="center"
        alignItems="center"
        bg={"primaryBackground"}
      >
        <Box textAlign="center" py={10} px={20}>
          {result === "success" && (
            <CheckCircleIcon boxSize={"50px"} color={"green.500"} />
          )}
          {result === "cancellation" && (
            <WarningTwoIcon boxSize={"50px"} color={"orange.300"} />
          )}
          {result === "failure" && (
            <Box display="inline-block">
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                bg={"red.500"}
                rounded={"50px"}
                w={"55px"}
                h={"55px"}
                textAlign="center"
              >
                <CloseIcon boxSize={"20px"} color={"white"} />
              </Flex>
            </Box>
          )}
          <Heading as="h2" size="xl" mt={6} mb={2} color={"heading"}>
            {headline}
          </Heading>
          <Text
            color={"text"}
            maxWidth={{ base: "100%", md: "80%", lg: "60%" }}
            mx="auto"
          >
            {body}
          </Text>
          <Button
            variant="primary"
            mt={6}
            onClick={() => navigate("/new-customer")}
          >
            Back to Home
          </Button>
        </Box>
      </Box>
    </>
  );
}
