import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  FormErrorMessage,
  Heading,
  Container,
  useBreakpointValue,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Flex,
  Text,
  Image,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import logo from "../assets/logos/logo.png";
import { apiUrl } from "../config";
import { useLanguage } from "../contexts/LanguageContext";
import { Select } from "@chakra-ui/react";

const ValidateCoupon = () => {
  const { language, setLanguage, translate } = useLanguage();
  const [alertState, setAlertState] = useState(null);
  const { customerCode: urlCustomerCode, lang } = useParams();

  useEffect(() => {
    if (lang === "fr") {
      setLanguage("fr");
    }
  }, [lang, setLanguage]);

  const validationSchema = Yup.object().shape({
    customerCode: Yup.string().required(
      translate("validateCoupon.validationErrors.customerCodeRequired")
    ),
    passcode: Yup.string().required(
      translate("validateCoupon.validationErrors.passcodeRequired")
    ),
  });

  const handleSubmit = async (values, actions) => {
    try {
      const response = await axios.post(`${apiUrl}/validateCoupon`, values);
      console.log("Response:", response.data);
      if (response.data.valid) {
        setAlertState({
          status: "success",
          title: translate("validateCoupon.alertValidTitle"),
          description: `${translate("validateCoupon.alertValidDescription")}: ${
            response.data.couponDetails
          }`,
        });
      } else {
        setAlertState({
          status: "error",
          title: translate("validateCoupon.alertInvalidTitle"),
          description: translate(
            `validateCoupon.alertMessages.${response.data.messageCode}`
          ),
        });
      }
    } catch (error) {
      console.error("Error:", error);
      setAlertState({
        status: "error",
        title: translate("validateCoupon.alertInvalidTitle"),
        description: translate("validateCoupon.alertMessages.6"),
      });
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Box
      minH="100vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      bg="primaryBackground"
    >
      <Flex justify="center" align="center" width="100px" mb={4}>
        <Box
          as="button"
          py={2}
          px={3}
          onClick={() => setLanguage("en")}
          color={language === "en" ? "blue.500" : "gray.500"}
          fontWeight={language === "en" ? "bold" : "normal"}
        >
          EN
        </Box>
        <Box px={1} color="gray.300">
          |
        </Box>
        <Box
          as="button"
          py={2}
          px={3}
          onClick={() => setLanguage("fr")}
          color={language === "fr" ? "blue.500" : "gray.500"}
          fontWeight={language === "fr" ? "bold" : "normal"}
        >
          FR
        </Box>
      </Flex>

      <Image src={logo} alt="Logo" width="150px" mb={8} />
      <Heading as="h1" size="lg" mb={5} textAlign="center" color="heading">
        {translate("validateCoupon.heading")}
      </Heading>
      <Text mb={10} textAlign="center" color="text" maxWidth="500px">
        {translate("validateCoupon.description")}
      </Text>
      <Box
        maxWidth="500px"
        width="100%"
        bg="secondaryBackground"
        p={10}
        borderRadius="xl"
        boxShadow="md"
      >
        {alertState && (
          <Alert status={alertState.status} mb={4} borderRadius="md">
            <AlertIcon />
            <Box flex="1">
              <AlertTitle>{alertState.title}</AlertTitle>
              <AlertDescription>{alertState.description}</AlertDescription>
            </Box>
          </Alert>
        )}
        <Formik
          initialValues={{ customerCode: urlCustomerCode || "", passcode: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <Form>
              <VStack spacing={4}>
                <Field name="customerCode">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.customerCode && form.touched.customerCode
                      }
                    >
                      <FormLabel htmlFor="customerCode">
                        {translate("validateCoupon.customerCode")}
                      </FormLabel>
                      <Input
                        {...field}
                        id="customerCode"
                        placeholder={translate(
                          "validateCoupon.customerCodePlaceholder"
                        )}
                      />
                      <FormErrorMessage>
                        {form.errors.customerCode}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="passcode">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.passcode && form.touched.passcode}
                    >
                      <FormLabel htmlFor="passcode">
                        {translate("validateCoupon.businessPasscode")}
                      </FormLabel>
                      <Input
                        {...field}
                        id="passcode"
                        type="password"
                        placeholder={translate(
                          "validateCoupon.businessPasscodePlaceholder"
                        )}
                      />
                      <FormErrorMessage>
                        {form.errors.passcode}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Button
                  mt={4}
                  variant={"primary"}
                  isLoading={props.isSubmitting}
                  type="submit"
                  width="100%"
                >
                  {translate("validateCoupon.validateButton")}
                </Button>
              </VStack>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default ValidateCoupon;
