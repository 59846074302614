import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
  FormErrorMessage,
  Heading,
  Text,
  Image,
  Flex,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { db } from "../firebase";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../assets/logos/logo.png";
import { apiUrl } from "../config";
import { useLanguage } from "../contexts/LanguageContext";

const AddCustomer = () => {
  const navigate = useNavigate();
  const { campaignId, agentId, lang } = useParams();
  const [campaigns, setCampaigns] = useState([]);
  const [agents, setAgents] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const { language, setLanguage, translate } = useLanguage();

  useEffect(() => {
    const fetchCampaigns = async () => {
      const campaignsRef = collection(db, "campaigns");
      const querySnapshot = await getDocs(campaignsRef);
      const campaignsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name,
      }));
      setCampaigns(campaignsData);
    };

    fetchCampaigns();

    if (campaignId) {
      fetchCampaignDetails(campaignId);
      fetchAgents(campaignId);
    }

    // Set language based on the 'lang' parameter
    if (lang === "fr") {
      setLanguage("fr");
    } else {
      setLanguage("en");
    }
  }, [campaignId, lang, setLanguage]);

  const initialValues = {
    campaignId: campaignId || "",
    agentId: agentId || "",
    name: "",
    email: "",
    confirmEmail: "",
    phoneNumber: "",
    language: language || "en",
  };

  const handleSubmit = async (values, actions) => {
    console.log("Values:", values);
    try {
      await axios
        .post(`${apiUrl}/create-checkout-session`, values)
        .then((response) => {
          if (response.status === 200) {
            window.location = response.data.url;
          } else {
            return Promise.reject(response.data);
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
      console.log("Customer added successfully");
      actions.setSubmitting(false);
      actions.resetForm();
    } catch (error) {
      console.error("Error adding customer:", error);
      actions.setSubmitting(false);
    }
  };

  const fetchCampaignDetails = async (campaignId) => {
    const campaignRef = doc(db, "campaigns", campaignId);
    const campaignSnap = await getDoc(campaignRef);
    if (campaignSnap.exists()) {
      setSelectedCampaign({
        id: campaignSnap.id,
        ...campaignSnap.data(),
      });
    } else {
      setSelectedCampaign(null);
    }
  };

  const fetchAgents = async (campaignId) => {
    const agentsRef = collection(db, "campaigns", campaignId, "agents");
    const agentsSnapshot = await getDocs(agentsRef);
    const agentsData = agentsSnapshot.docs.map((doc) => ({
      id: doc.id,
      name: doc.data().name,
    }));
    setAgents(agentsData);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(
      translate("addCustomer.validationErrors.nameRequired")
    ),
    email: Yup.string()
      .email(translate("addCustomer.validationErrors.invalidEmail"))
      .required(translate("addCustomer.validationErrors.emailRequired")),
    confirmEmail: Yup.string()
      .oneOf(
        [Yup.ref("email"), null],
        translate("addCustomer.validationErrors.emailsMustMatch")
      )
      .required(translate("addCustomer.validationErrors.confirmEmailRequired")),
    campaignId: Yup.string().required(
      translate("addCustomer.validationErrors.campaignRequired")
    ),
    phoneNumber: Yup.string().test(
      "valid-phone",
      translate("addCustomer.validationErrors.phoneNumberInvalid"),
      (value) => {
        if (!value) return true; // Optional field
        // Add your phone number validation logic here
        return /^[0-9+\-\s()]*$/.test(value);
      }
    ),
  });

  return (
    <Box
      minH="100vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      bg="primaryBackground"
      py={20}
    >
      <Flex justify="center" align="center" width="100px" mb={4}>
        <Box
          as="button"
          py={2}
          px={3}
          onClick={() => setLanguage("en")}
          color={language === "en" ? "blue.500" : "gray.500"}
          fontWeight={language === "en" ? "bold" : "normal"}
        >
          EN
        </Box>
        <Box px={1} color="gray.300">
          |
        </Box>
        <Box
          as="button"
          py={2}
          px={3}
          onClick={() => setLanguage("fr")}
          color={language === "fr" ? "blue.500" : "gray.500"}
          fontWeight={language === "fr" ? "bold" : "normal"}
        >
          FR
        </Box>
      </Flex>
      <Image src={logo} alt="Logo" width="150px" mb={8} />
      <Heading as="h1" size="lg" mb={5} textAlign="center" color="heading">
        {translate("addCustomer.heading")}
      </Heading>
      <Text mb={10} px={10} textAlign="center" color="text" maxWidth="500px">
        {translate("addCustomer.description")}
      </Text>
      <Box
        maxWidth="500px"
        width="100%"
        bg="secondaryBackground"
        p={10}
        borderRadius="xl"
        boxShadow="md"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <Form>
              <VStack spacing={4}>
                {selectedCampaign && (
                  <Box width="100%" p={4} bg="gray.100" borderRadius="md">
                    <Heading size="md" mb={2}>
                      {selectedCampaign.name}
                    </Heading>
                    <Text>{selectedCampaign.description}</Text>
                    <Text mt={2} fontWeight="bold">
                      {translate("addCustomer.couponBookPrice")}: $
                      {selectedCampaign.couponBookPrice}
                    </Text>
                    <Button
                      onClick={() => {
                        if (selectedCampaign) {
                          if (selectedCampaign.offeringURL) {
                            window.open(selectedCampaign.offeringURL, "_blank");
                          } else {
                            navigate(`/business-list/${selectedCampaign.id}`);
                          }
                        }
                      }}
                      variant={"secondary"}
                      mt={2}
                      size={"sm"}
                    >
                      {translate("addCustomer.seeOfferingsButton")}
                    </Button>
                  </Box>
                )}

                <Field name="campaignId">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.campaignId && form.touched.campaignId
                      }
                    >
                      <FormLabel htmlFor="campaignId">
                        {translate("addCustomer.campaign")}
                      </FormLabel>
                      <Select
                        {...field}
                        id="campaignId"
                        placeholder={translate("addCustomer.selectCampaign")}
                        isDisabled={!!campaignId}
                        onChange={(e) => {
                          field.onChange(e);
                          fetchCampaignDetails(e.target.value);
                          fetchAgents(e.target.value);
                        }}
                      >
                        {campaigns.map((campaign) => (
                          <option key={campaign.id} value={campaign.id}>
                            {campaign.name}
                          </option>
                        ))}
                      </Select>
                      <FormErrorMessage>
                        {form.errors.campaignId}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Field name="agentId">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.agentId && form.touched.agentId}
                    >
                      <FormLabel htmlFor="agentId">
                        {translate("addCustomer.fundraiser")}
                      </FormLabel>
                      <Select
                        {...field}
                        id="agentId"
                        placeholder={translate("addCustomer.selectFundraiser")}
                        isDisabled={!form.values.campaignId || !!agentId}
                      >
                        {agents.map((agent) => (
                          <option key={agent.id} value={agent.id}>
                            {agent.name}
                          </option>
                        ))}
                      </Select>
                      <FormErrorMessage>{form.errors.agentId}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Field name="name">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.name && form.touched.name}
                    >
                      <FormLabel htmlFor="name">
                        {translate("addCustomer.name")}
                      </FormLabel>
                      <Input
                        {...field}
                        id="name"
                        placeholder={translate("addCustomer.namePlaceholder")}
                      />
                      <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Field name="email">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.email && form.touched.email}
                    >
                      <FormLabel htmlFor="email">
                        {translate("addCustomer.email")}
                      </FormLabel>
                      <Input
                        {...field}
                        id="email"
                        placeholder={translate("addCustomer.emailPlaceholder")}
                      />
                      <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Field name="confirmEmail">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.confirmEmail && form.touched.confirmEmail
                      }
                    >
                      <FormLabel htmlFor="confirmEmail">
                        {translate("addCustomer.confirmEmail")}
                      </FormLabel>
                      <Input
                        {...field}
                        id="confirmEmail"
                        placeholder={translate(
                          "addCustomer.confirmEmailPlaceholder"
                        )}
                      />
                      <FormErrorMessage>
                        {form.errors.confirmEmail}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Field name="phoneNumber">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.phoneNumber && form.touched.phoneNumber
                      }
                    >
                      <FormLabel htmlFor="phoneNumber">
                        {translate("addCustomer.phoneNumber")}
                      </FormLabel>
                      <Input
                        {...field}
                        id="phoneNumber"
                        placeholder={translate(
                          "addCustomer.phoneNumberPlaceholder"
                        )}
                      />
                      <FormErrorMessage>
                        {form.errors.phoneNumber}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Button
                  mt={4}
                  variant={"primary"}
                  isLoading={props.isSubmitting}
                  type="submit"
                  width="100%"
                >
                  {translate("addCustomer.proceedButton")}
                </Button>
              </VStack>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default AddCustomer;
