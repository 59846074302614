import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
  Select,
  useToast,
  Heading,
  Container,
  useBreakpointValue,
  Text,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { db } from "../firebase";
import { collection, addDoc, query, where, getDocs } from "firebase/firestore";
import LogoutButton from "../components/LogoutButton";
import Navbar from "../components/Navbar";
import { apiUrl } from "../config";
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Business name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phoneNumber: Yup.string()
    .matches(/^\d{10}$/, "Phone number must be 10 digits")
    .required("Phone number is required"),
  passcode: Yup.string()
    .min(4, "Passcode must be at least 4 characters")
    .required("Passcode is required"),
  confirmPasscode: Yup.string()
    .oneOf([Yup.ref("passcode"), null], "Passcodes must match")
    .required("Confirm passcode is required"),
  websiteUrl: Yup.string(),
  couponDetails: Yup.string().required("Coupon details are required"),
  campaignId: Yup.string().required("Campaign is required"),
  agentId: Yup.string().required("Fundraiser is required"),
  description: Yup.string()
    .max(75, "Description must be at most 75 characters")
    .required("Business description is required"),
});

const AddBusinessForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [campaigns, setcampaigns] = useState([]);
  const [agents, setAgents] = useState([]);
  const toast = useToast();

  const headingSize = useBreakpointValue({ base: "xl", md: "xl" });
  const buttonSize = useBreakpointValue({ base: "md", md: "md" });
  const containerWidth = useBreakpointValue({
    base: "100%",
    md: "80%",
    lg: "60%",
  });

  useEffect(() => {
    const fetchcampaigns = async () => {
      const campaignsRef = collection(db, "campaigns");
      const querySnapshot = await getDocs(campaignsRef);
      const campaignsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name,
      }));
      setcampaigns(campaignsData);
    };

    fetchcampaigns();
  }, []);

  const handleShowClick = (field) => {
    if (field === "passcode") {
      setShowPassword(!showPassword);
    } else {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  const initialValues = {
    name: "",
    email: "",
    phoneNumber: "",
    passcode: "",
    confirmPasscode: "",
    websiteUrl: "",
    couponDetails: "",
    campaignId: "",
    agentId: "",
    description: "",
  };

  const handleSubmit = async (values, actions) => {
    try {
      const businessesRef = collection(db, "businesses");
      const q = query(businessesRef, where("passcode", "==", values.passcode));
      const querySnapshot = await getDocs(q);
      const idToken = sessionStorage.getItem("idToken");

      if (!querySnapshot.empty) {
        actions.setFieldError(
          "passcode",
          "A business with this passcode already exists"
        );
        actions.setSubmitting(false);
        return;
      }
      const response = await axios.post(
        `${apiUrl}/addBusiness`,
        {
          ...values,
          campaignId: values.campaignId,
          agentId: values.agentId,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      console.log("Business added successfully:", response.data);
      actions.setSubmitting(false);
      actions.resetForm();
      toast({
        title: "Success",
        description: "Business added successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error adding business:", error);
      actions.setSubmitting(false);
      toast({
        title: "Error",
        description: "Failed to add business. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Navbar />
      <Box minH="calc(100vh - 60px)" bg="primaryBackground" pt="60px" px={4}>
        <VStack
          spacing={8}
          align="center"
          maxWidth="500px"
          margin="0 auto"
          pt={8}
        >
          <Heading as="h1" size="lg" textAlign="center" color="heading">
            Add Advertiser
          </Heading>
          <Box
            width="100%"
            bg="secondaryBackground"
            p={10}
            borderRadius="xl"
            boxShadow="md"
          >
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {(props) => (
                <Form>
                  <VStack spacing={4}>
                    <Field name="name">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.name && form.touched.name}
                          isRequired
                        >
                          <FormLabel htmlFor="name">Business Name</FormLabel>
                          <Input
                            {...field}
                            id="name"
                            placeholder="Enter business name"
                          />
                          <FormErrorMessage>
                            {form.errors.name}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="email">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.email && form.touched.email}
                          isRequired
                        >
                          <FormLabel htmlFor="email">Email</FormLabel>
                          <Input
                            {...field}
                            id="email"
                            placeholder="Enter email"
                          />
                          <FormErrorMessage>
                            {form.errors.email}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="phoneNumber">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.phoneNumber && form.touched.phoneNumber
                          }
                          isRequired
                        >
                          <FormLabel htmlFor="phoneNumber">
                            Phone Number
                          </FormLabel>
                          <Input
                            {...field}
                            id="phoneNumber"
                            placeholder="Enter phone number"
                          />
                          <FormErrorMessage>
                            {form.errors.phoneNumber}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="passcode">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.passcode && form.touched.passcode
                          }
                          isRequired
                        >
                          <FormLabel htmlFor="passcode">Passcode</FormLabel>
                          <InputGroup>
                            <Input
                              {...field}
                              id="passcode"
                              type={showPassword ? "text" : "password"}
                              placeholder="Enter passcode"
                            />
                            <InputRightElement width="4.5rem">
                              <Button
                                h="1.75rem"
                                size="sm"
                                onClick={() => handleShowClick("passcode")}
                              >
                                {showPassword ? "Hide" : "Show"}
                              </Button>
                            </InputRightElement>
                          </InputGroup>
                          <FormErrorMessage>
                            {form.errors.passcode}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="confirmPasscode">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.confirmPasscode &&
                            form.touched.confirmPasscode
                          }
                          isRequired
                        >
                          <FormLabel htmlFor="confirmPasscode">
                            Confirm Passcode
                          </FormLabel>
                          <InputGroup>
                            <Input
                              {...field}
                              id="confirmPasscode"
                              type={showConfirmPassword ? "text" : "password"}
                              placeholder="Confirm passcode"
                            />
                            <InputRightElement width="4.5rem">
                              <Button
                                h="1.75rem"
                                size="sm"
                                onClick={() =>
                                  handleShowClick("confirmPasscode")
                                }
                              >
                                {showConfirmPassword ? "Hide" : "Show"}
                              </Button>
                            </InputRightElement>
                          </InputGroup>
                          <FormErrorMessage>
                            {form.errors.confirmPasscode}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="websiteUrl">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.websiteUrl && form.touched.websiteUrl
                          }
                        >
                          <FormLabel htmlFor="websiteUrl">
                            Website URL
                          </FormLabel>
                          <Input
                            {...field}
                            id="websiteUrl"
                            placeholder="Enter website URL"
                          />
                          <FormErrorMessage>
                            {form.errors.websiteUrl}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="description">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.description && form.touched.description
                          }
                          isRequired
                        >
                          <FormLabel htmlFor="description">
                            Business Description
                          </FormLabel>
                          <Textarea
                            {...field}
                            id="description"
                            placeholder="Enter business description (max 75 characters)"
                            maxLength={75}
                          />
                          <FormErrorMessage>
                            {form.errors.description}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="couponDetails">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.couponDetails &&
                            form.touched.couponDetails
                          }
                          isRequired
                        >
                          <FormLabel htmlFor="couponDetails">
                            Coupon Details
                          </FormLabel>
                          <Textarea
                            {...field}
                            id="couponDetails"
                            placeholder="Enter coupon details (eg: 10% off all regular priced items.)"
                          />
                          <FormErrorMessage>
                            {form.errors.couponDetails}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="campaignId">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.campaignId && form.touched.campaignId
                          }
                          isRequired
                        >
                          <FormLabel htmlFor="campaignId">Campaign</FormLabel>
                          <Select
                            {...field}
                            id="campaignId"
                            placeholder="Select campaign"
                            onChange={(e) => {
                              field.onChange(e);
                              const fetchAgents = async () => {
                                const agentsRef = collection(
                                  db,
                                  "campaigns",
                                  e.target.value,
                                  "agents"
                                );
                                const agentsSnapshot = await getDocs(agentsRef);
                                const agentsData = agentsSnapshot.docs.map(
                                  (doc) => ({
                                    id: doc.id,
                                    name: doc.data().name,
                                  })
                                );
                                setAgents(agentsData);
                              };
                              fetchAgents();
                            }}
                          >
                            {campaigns.map((campaign) => (
                              <option key={campaign.id} value={campaign.id}>
                                {campaign.name}
                              </option>
                            ))}
                          </Select>
                          <FormErrorMessage>
                            {form.errors.campaignId}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="agentId">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.agentId && form.touched.agentId
                          }
                          isRequired
                        >
                          <FormLabel htmlFor="agentId">Fundraiser</FormLabel>
                          <Select
                            {...field}
                            id="agentId"
                            placeholder="Select Fundraiser"
                            isDisabled={!form.values.campaignId}
                          >
                            {agents.map((agent) => (
                              <option key={agent.id} value={agent.id}>
                                {agent.name}
                              </option>
                            ))}
                          </Select>
                          <FormErrorMessage>
                            {form.errors.agentId}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Button
                      mt={4}
                      variant={"primary"}
                      isLoading={props.isSubmitting}
                      type="submit"
                      width="100%"
                    >
                      Add Advertiser
                    </Button>
                  </VStack>
                </Form>
              )}
            </Formik>
          </Box>
        </VStack>
      </Box>
    </>
  );
};

export default AddBusinessForm;
