import { createContext, useContext, useEffect, useState } from "react";
import {
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { db, auth } from "../firebase";

// import { apiUrl } from "../config";
import { useNavigate } from "react-router-dom";

import { doc, getDoc } from "firebase/firestore";

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const uid = user.uid;
      const email = user.email;
      const firstName = user.displayName.split(" ")[0];
      const lastName = user.displayName.split(" ").slice(1).join(" ");

      sessionStorage.setItem("uid", uid);
      sessionStorage.setItem("firstName", firstName);
      sessionStorage.setItem("lastName", lastName);
      setUser({
        uid,
        email,
        firstName,
        lastName,
      });
    } catch (error) {
      console.error("Error signing in with Google:", error.message);
      throw error;
    }
  };

  const logout = async () => {
    if (sessionStorage.length !== 0) {
      sessionStorage.clear();
      await signOut(auth);
      setUser(null);
      navigate("/");
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        const idToken = await currentUser.getIdToken(true);
        sessionStorage.setItem("idToken", idToken);

        const uid = currentUser.uid;
        const userDoc = await getDoc(doc(db, "users", uid));
        const userData = userDoc.data();

        if (userData) {
          sessionStorage.setItem("uid", uid);
          sessionStorage.setItem("firstName", userData.firstName);
          sessionStorage.setItem("lastName", userData.lastName);
          setUser({
            uid,
            email: currentUser.email,
            firstName: userData.firstName,
            lastName: userData.lastName,
          });
        }
      } else {
        logout();
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        logout,

        signInWithGoogle,
      }}
    >
      {!loading && children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};
