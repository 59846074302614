import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  VStack,
  Heading,
  Text,
  Link,
  Spinner,
  Flex,
  Image,
  SimpleGrid,
} from "@chakra-ui/react";
import { db } from "../firebase";
import logo from "../assets/logos/logo.png";
import { useLanguage } from "../contexts/LanguageContext";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";

const BusinessList = () => {
  const { campaignId } = useParams();
  const [businesses, setBusinesses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [campaignName, setCampaignName] = useState("");
  const { language, setLanguage, translate } = useLanguage();

  useEffect(() => {
    const fetchBusinessesAndCampaign = async () => {
      try {
        // Fetch campaign name
        const campaignRef = doc(db, "campaigns", campaignId);
        const campaignSnap = await getDoc(campaignRef);
        if (campaignSnap.exists()) {
          setCampaignName(campaignSnap.data().name);
        } else {
          setError("Campaign not found.");
          return;
        }

        // Fetch businesses
        const businessesRef = collection(db, "businesses");
        const q = query(businessesRef, where("campaignId", "==", campaignId));
        const querySnapshot = await getDocs(q);
        const businessList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBusinesses(businessList);
      } catch (err) {
        console.error("Error fetching data: ", err);
        setError("An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchBusinessesAndCampaign();
  }, [campaignId]);

  if (loading) {
    return (
      <Box
        minH="100vh"
        bg="primaryBackground"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Spinner size="xl" color="blue.500" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        minH="100vh"
        bg="primaryBackground"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Text color="red.500">{error}</Text>
      </Box>
    );
  }

  return (
    <Box
      minH="100vh"
      display="flex"
      flexDirection="column"
      bg="primaryBackground"
    >
      <Flex justify="space-between" align="center" p={4} flexWrap="wrap">
        <Image src={logo} alt="Logo" width={["80px", "100px"]} />
        <Flex align="center" mt={[4, 0]}>
          <Box
            as="button"
            py={2}
            px={3}
            onClick={() => setLanguage("en")}
            color={language === "en" ? "blue.500" : "gray.500"}
            fontWeight={language === "en" ? "bold" : "normal"}
          >
            EN
          </Box>
          <Box px={1} color="gray.300">
            |
          </Box>
          <Box
            as="button"
            py={2}
            px={3}
            onClick={() => setLanguage("fr")}
            color={language === "fr" ? "blue.500" : "gray.500"}
            fontWeight={language === "fr" ? "bold" : "normal"}
          >
            FR
          </Box>
        </Flex>
      </Flex>

      <VStack
        align="stretch"
        maxWidth="1200px"
        margin="0 auto"
        pt={8}
        px={4}
        pb={8}
      >
        <Heading
          as="h1"
          size={["lg", "xl"]}
          textAlign="center"
          color="text"
          mt={10}
        >
          {translate("businessList.heading")}
        </Heading>
        <Heading
          as={"h3"}
          size={["sm", "md"]}
          color="heading"
          mb={5}
          textAlign="center"
        >
          {campaignName}
        </Heading>
        <Text mb={10} textAlign="center" fontSize={["sm", "md"]}>
          {translate("businessList.description", { campaignName })}
        </Text>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
          {businesses.map((business) => (
            <Box
              key={business.id}
              p={6}
              bg="secondaryBackground"
              borderRadius="xl"
              boxShadow="md"
            >
              <Heading as="h3" size="md" mb={3} color="text">
                {business.name}
              </Heading>
              <Text mb={3} color="text">
                {business.description}
              </Text>
              <Link
                href={business.websiteUrl}
                isExternal
                color="blue.500"
                mb={3}
                display="block"
              >
                {translate("businessList.website")}
              </Link>
              <Text fontWeight="bold" color="text">
                {translate("businessList.couponDetails")}:
              </Text>
              <Text color="text">{business.couponDetails}</Text>
            </Box>
          ))}
        </SimpleGrid>
      </VStack>
    </Box>
  );
};

export default BusinessList;
