import React from "react";
import { Box, Flex, Image, Button, useBreakpointValue } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import LogoutButton from "./LogoutButton";
import logo from "../assets/logos/logo.png";

const Navbar = () => {
  const navigate = useNavigate();
  const logoSize = useBreakpointValue({ base: "75px", md: "75px" });
  const buttonSize = useBreakpointValue({ base: "sm", md: "md" });

  const handleLogoClick = () => {
    navigate("/home");
  };

  return (
    <Box as="nav" bg="white" boxShadow="sm" py={5} px={4}>
      <Flex justify="space-between" align="center" maxW="1200px" mx="auto">
        <Button
          onClick={handleLogoClick}
          variant="unstyled"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Image src={logo} alt="Logo" w={logoSize} h="auto" />
        </Button>
        <LogoutButton />
      </Flex>
    </Box>
  );
};

export default Navbar;
